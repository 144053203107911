<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span>{{ model.title }}</span></h2>
          <div class="event_data">
            <div class="detail flex"><span class="tag">中継会場</span>{{ model.relay_place }}</div>
          </div>
          <div class="mov_wrap">
            <div class="youtube" v-html="model.youtube_code"></div>
          </div>
        </div>

        <div class="cts_box" v-if="model.pdf_file_url">
          <div class="mov_archves_list bdr_none">
            <h3 class="min_ttl">教材ダウンロード</h3>
            <div class="mov_archives flex">
              <a v-bind:href="model.pdf_file_url" class="flex" target="_blank">
                <div class="thumb"><img src="/common/images/thumb_pdf.png" alt=""></div>
                <div class="info" v-html="model.pdf_text"></div>
              </a>
            </div>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex" v-if="model.instructor">
              <dt>講師</dt>
              <dd v-html="model.instructor"></dd>
            </dl>
            <dl class="flex">
              <dt>現地参加募集</dt>
              <dd>
                先着順 {{ model.sales_count }}名<br>
                <span class="red" v-if="model.full">※満席のため、現地参加お申込みを締め切りました。生配信をご覧ください。</span><!--販売数超えたら表示？-->
              </dd>
            </dl>
            <dl class="flex">
              <dt>参加資格</dt>
              <dd>複眼経済塾塾生の方
                <template v-if="model.rank_regular_plus">（本科プラス）</template>
                <template v-if="model.rank_regular || model.rank_regular_default">（本科）</template>
                <template v-if="model.rank_regular_plus">（予科）</template>
              </dd>
            </dl>
            <dl class="flex">
              <dt>申込期限</dt>
              <dd>{{ moment(model.live_close_date, 'YYYY/MM/DD (ddd) HH:mm') }}</dd>
            </dl>
            <dl class="flex">
              <dt>価格</dt>
              <dd>
                <div v-if="model.rank_regular_plus">塾生(本科プラス)割引価格：{{
                    delimited(model.price_rank_regular_plus)
                  }}円･税込
                </div>
                <div v-if="model.rank_regular && !isRegularDefault()">塾生(本科)割引価格：{{
                    delimited(model.price_rank_regular)
                  }}円･税込
                </div>
                <div v-if="model.rank_regular_default && isRegularDefault()">
                  塾生(本科)割引価格：{{ delimited(model.price_rank_regular_default) }}円･税込
                </div>
                <div v-if="model.rank_preparatory">塾生(予科)割引価格：{{ delimited(model.price_rank_preparatory) }}円･税込</div>
                <div v-if="model.rank_student">塾生(学生科)割引価格：{{ delimited(model.price_rank_student) }}円･税込</div>
                <div v-if="false /*model.rank_corporation*/">塾生(法人科)割引価格：{{ delimited(model.price_rank_corporation) }}円･税込</div>
                <br>
                ※ただし塾生のみ参加可<br>
                （オンラインで生中継をご視聴いただく場合は申込は必要ありません。 下記ボタンより時間になりましたらご視聴ください。）
              </dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>中継会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="model.note_text">
              <dt>備考</dt>
              <dd v-html="model.note_text"></dd>
            </dl>
          </div>
        </div>

        <div id="event_map" class="cts_box">
          <div class="btn_wrap event_cv">
            <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }"
                         class="submit light skeleton arrow back">前のページにもどる
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei/page/${this.id}.json`, {})
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.monthly_live
            if (!this.model.live_active) {
              this.$router.push({name: 'GetsureiShow', params: {id: this.model.id}})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
